@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100;1,300&family=Montserrat:wght@500&family=Playfair+Display&family=Poppins:wght@200&family=Raleway:ital,wght@1,900&family=Roboto+Serif:wght@100&family=Roboto:ital,wght@0,100;0,300;1,100&family=Shizuru&display=swap');

 .spinner-border .sr-only{
    text-align: center! important;
}
.underline{
    height: 2px;
    margin: 22px auto 26px;
    width: 50px;
    background: var(--color-button);
}
.latest-art{
    font-size: 3.0rem;
    font-weight: 500;
    cursor: pointer;
    color: var(--color-topic);
    margin-top: 50px;
    font-family: 'Montserrat', sans-serif;
}
.two .img-fluid{
    height: 363.32px;
   
}
@media screen and (max-width:375px) {
    .latest-art{
        font-size: 2.0rem;
        font-weight: 700;
        cursor: pointer;
    }
}

  .one{
      margin-bottom: 30px;
  }
  .two{
    position:relative;
    cursor: pointer;
   
}
.card-overlay{
    position: relative;
    /* z-index: 1; */
    
    
   
  }
  .card-overlay:after {
    content:'';
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.5);
  
  }
.four{
    position: absolute;
    top: 31%;
    left: 9%;
}


.two:hover:after{ 
    content:'';
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.featured-card:hover:after{ 
    content:'';
    position:absolute;
    left:0px;
    top:0px;
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.5);
}

.card-btn{
    background-color: var(--color-button);
    color: white;
    z-index: 3;
}
.card-btn:hover{
    background-color: white;
    color: var(--color-button);
}
.four h3{
    z-index: 3;
}
.owl-nav{
    font-size: 30px;
    color: var(--color-button);
}
