@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100;1,300&family=Montserrat:wght@500&family=Playfair+Display&family=Poppins:wght@200&family=Raleway:ital,wght@1,900&family=Roboto+Serif:wght@100&family=Roboto:ital,wght@0,100;0,300;1,100&family=Shizuru&display=swap');


#main-main{
    font-size: 50px;
}
.first-banner{
    margin-top: 130px ;
    margin-bottom: 30px;
}

    .first-ban-intro h2{
margin-bottom: 24px;
    font-size: 5.2rem;
    font-weight: 900;
    font-family: 'Muli', Arial, sans-serif;
    font-weight: 900;
    line-height: 1.1;
    margin-top: 0;
    color: var(--color-topic);
    font-family: 'Montserrat', sans-serif;
    }
    .first-ban-intro p{
        font-family: 'Montserrat', sans-serif;
    }
    @media screen and (max-width:800px ){
        #main-main{
            margin-bottom: 24px;
                font-size: 2.2rem;
                font-weight: 900;
                font-family: 'Muli', Arial, sans-serif;
                font-weight: 900;
                line-height: 1.1;
                margin-top: 0;
                color: var(--color-topic);
                }
        
    }
    .first-image{
        border-radius: 63% 37% 50% 50%/70% 68% 32% 30%;
        box-shadow: 200px 0px #f2f3f3;
    }
    .first-btn{
        background: var(--color-button);
        color: white;
        margin: 1px 10px;
        padding: 8px 14px;
        font-family: 'Montserrat', sans-serif;
       
    }
    .first-btn:hover{
        box-shadow: 0 0 0 0 rgb(241 59 59 / 0%);
        color: #fff;
    }
    .btn:hover{
        color: #fff;
    }
    .btn:focus{
        box-shadow: none;
    }
    .btn{
        color: #fff!important;
        background-color: var(--color-button)!important;

    }