@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100;1,300&family=Montserrat:wght@500&family=Playfair+Display&family=Poppins:wght@200&family=Raleway:ital,wght@1,900&family=Roboto+Serif:wght@100&family=Roboto:ital,wght@0,100;0,300;1,100&family=Shizuru&display=swap');

 

.btn-read{
    border: none;
    color: #000;
    border-radius: 6px;
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
    height: 40px;
    width: 150px;
}
  .color-change{
      color: var(--color-button);
      font-family: 'Montserrat', sans-serif;
  }
  .motivation-section p{
      font-size: 17px;
      line-height: 26px;
      font-family: 'Montserrat', sans-serif;
      }
   .motivation-section-head{
    font-weight: 700;
    font-size: 42px;
    color: var(--color-topic);
    font-family: 'Montserrat', sans-serif;
   }
   .motivation-section-head:hover{
color: var(--color-button);
cursor: pointer;
   }
   .featured-article{
    font-size: 3.0rem;
    font-weight: 500;
    cursor: pointer;
    color: var(--color-topic);
    margin-top: 50px;
    font-family: 'Montserrat', sans-serif;
}

/* .image-main .img-fluid{
    height: 480px;
    width: 480px;
} */
@media screen and (max-width:375px) {
    .featured-article{
        font-size: 2.0rem;
        font-weight: 700;
        cursor: pointer;
    }
}
@media screen and (max-width:375px) {
    .subscribe-btn{
       
        width: 0px;
       
    }
}
.img-item img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    
}
.img-item{
    height: 400px;
    width: 100%;
    
    overflow: hidden;
}
.card-news{
    background-color: #f2f3f3;
    padding-bottom: 20px;
    padding: 50px;
    
}
.subscribe-btn{
    border: none;
    background-color: var(--color-button);
    color: #fff;
    text-align: center;
    font-weight: bold;
    height: 45px;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
}
.form-control:focus{
    box-shadow: none;
    
}
