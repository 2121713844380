@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100;1,300&family=Montserrat:wght@500&family=Playfair+Display&family=Poppins:wght@200&family=Raleway:ital,wght@1,900&family=Roboto+Serif:wght@100&family=Roboto:ital,wght@0,100;0,300;1,100&family=Shizuru&display=swap');
*{
    margin: 0%;
  }

.First-main{
    text-align: center;
}
.btn-main{
    border: none;
    color: #fff;
    background-color: #f13b3b;
    margin-top: 130px;
    height: 38px;
    width: auto;
    font-weight: bolder;
    padding: 2px 6px 5px 8px;
    
}
.btn-main:hover{
        background-color: #424851;
        color: #fff;
    }
    .creativity {
        font-weight: bold;
    }
.creativity h2{
    color: #424851;
    font-weight: 900;
    font-size: 45px;
    font-family: 'Montserrat', sans-serif;
}
.writer{
    text-align: right;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
    margin-left: 0px;
    
}
.writer .by{
    color:#9ba3a7;
    margin: 0! important;
    font-size: 12px;
 }
 .writer b{
     color:#f13b3b;
  }
  
.proactively{
   text-align: justify;
   font-family: 'Montserrat', sans-serif;
   /* padding-left: 0px; */

}
#sidebarWrap {
    height: 400px;
    width: 210px;
    float: right;
    position: relative;
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
}


.proactively p{
    font-weight: 450;
 }
/* .card {
    padding: 20px;
    background-color: #f2f3f3;
    border: none;
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
} */
.letter-main{
     font-weight: 750;
     font-size: 17px;
     color: #424851;
    
 }
 .form-control{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #42485133;
 }


   
  
 @media only screen and (max-width:844px){
   .creativity h2{
       font-size: 25px;
       text-align: left;
       padding-left: 14px;
   }
}
@media only screen and (max-width:844px) {
    .writer{
        text-align: left;
        padding: 8px;
    }
} 




.linee{
    border-bottom: 1px solid #42485133;
}
.face{
    border: none;
    background-color: var(--color-facebook);
    width: 118px;
    height: 34px;
    
}
.face i{
    color: #fff;
}
.twitter{
    border: none;
    background-color: #4da7de;
    width: 118px;
    height: 34px;
}
.twitter i{
    color: #fff;
}
.linkedin{
    border: none;
    background-color: #61aceb;
    width: 118px;
    height: 34px;
}
.linkedin i{
    color: #fff;
}
.email{
    border: none;
    background-color: #000;
    width: 118px;
    height: 34px;
}
.email i{
    color: #fff;
}

@media only screen and (max-width:667px) {
    .twitter{
       width: 70px; 
    }
    .face{
        width: 70px; 
     }
     .linkedin{
        width: 70px; 
     }
     .email{
         width: 64px;
     }
}
.icon-item a{
    color: #fff;
}
.icon-item1 a{
    color: #fff;
}
.circle .icon-item2  {
  background-color: #61aceb;
   
}
.icon-item2 i{
    color: #fff;
}
.circle .icon-item3  {
    background-color: #e23f68;
     
  }
  .icon-item3 i{
      color: #fff;
  }
   
    /* <lets get social> */


    .card-social{
        border: none;
        background-color: #424851;
        padding: 20px;
        height: 237px;
        }     
        .card-social h3{
        color: #fff;
        }
        .card-social p{
        color: #fff
        }
        .icon-item{
            border-radius: 50px;
            height: 50px;
            width: 50px;
            font-size: 20px;
            color: #fff;
            background-color: var(--color-facebook);
            padding: 11px 0 0 19px;
            cursor: pointer;
         }
          .icon-item1{
            border-radius: 50px;
            height: 50px;
            width: 50px;
            transition: 0.3s;
            font-size: 20px;
            color: #fff;
            background-color: #4da7de;
            padding: 11px 0 0 16px;
            cursor: pointer;
         }
         
          .icon-item2{
            border-radius: 50px;
            height: 50px;
            width: 50px;
            font-size: 20px;
            color: #34b7a7;
            background-color: #c92619;
            padding: 11px 0 0 18px;
            cursor: pointer;
         }
          .icon-item3{
            border-radius: 50px;
            height: 50px;
            width: 50px;
            font-size: 20px;
            color: #34b7a7;
            background-color: #000000;
            padding: 11px 0 0 17px;
            cursor: pointer;
         }

   /* <Reading> */



   .reading{
    background-color:#424851;
    font-family: 'Montserrat', sans-serif;
    padding-top: 70px;
}

.reading h2{
    color: #fff;
    text-align: center;
}
.underline{
    height: 2px;
    margin: 22px auto 26px;
    width: 30px;
    background: #f13b3b ;
}
.card .card-reading .reading-section{
    border: none;
    background-color:#424851;
}
.top{
    color:#fff;
    font-size: 25px;
    font-weight: 700;
}

.btn-art{
    color: #fff;
    background-color: #f13b3b;
    border: none;
    font-size: 12px;
    font-weight: bolder;
    width: auto;
    height: 38px;
    padding: 2px 6px 5px 8px;
}

.pricing-card{
    list-style-type: none;
    text-align: center;
    cursor: pointer;
}
.pricing-card a{
    text-decoration: none;
    color: #424851;
}
.price-card-body{
    border: 1px solid #ddd;
    height: 50px;
    text-align: center;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #f4f9fc;

   
}
.card-header{
    border-bottom: 1px solid #ddd;
    height: 50px;
}
.card-price{
    border-bottom: 1px solid #ddd;
    height: 50px;
    text-align: center;
}
.image1{
    height: 200px;
    width: 100%;
    object-fit: cover;
}
.image1 img{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
  .page a{
      text-decoration: none;
  }
  .similar h5{
    color:#ff0000;
  }
  
  .famous p{
   margin-left: 15px;
   display: block;
   background-color: #f2f3f3;
   padding: 6px;
   border-radius: 6px;
  }
  .famous a{
      text-decoration: none;
      color: #000000;
      
  }
 .rcs-button{
     border: none;
 }
 /* @media only screen and (min-width:360px) and (max-width:667px) {
     .First-main{
         margin-top: 180px;
     }
 }
 @media only screen and (min-width:280px) and (max-width:653px) {
    .First-main{
        margin-top: 180px;
    }
}
 @media only screen and  (min-width:912px) and (max-width:1368px) {
    .First-main{
        margin-top: 180px;
    }
}
@media only screen and  (min-width:768px) and (max-width:1024px) {
    .First-main{
        margin-top: 180px;
    }
} */