.tag-main{
    margin-top: 100px;
    overflow: hidden;
}
.tag-main h3{
    text-align: center;
    color: var(--color-title);
}
.line{
    position: relative;
    left: 600px;
    width: 40px;
    height: 5px;
    background:  var(--color-title);
}
