
.categories{
    margin-top: 150px;
    overflow: hidden;
}

.spinner-border .sr-only{
    text-align: center;
}
/* .categories{
   height: 363.32px;
    width: 363.32px;
}
.image-fit .img-fluid{
    height: 363.32px;
    width: 363.32px;
    
} */
.btn .card-btn{
    text-align: center;
}
.card .categories{
    height: 400px;
    width: 100px;
}
