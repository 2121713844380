@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100;1,300&family=Montserrat:wght@500&family=Playfair+Display&family=Poppins:wght@200&family=Raleway:ital,wght@1,900&family=Roboto+Serif:wght@100&family=Roboto:ital,wght@0,100;0,300;1,100&family=Shizuru&display=swap");

.raju-sidebar {
  position: fixed;
  z-index: 1;
  padding: 20px;
}
.bla {
  overflow: scroll;
  overflow-x: hidden;
}
.bla::-webkit-scrollbar {
  display: none;
 
}




.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.index{
    z-index: 1;
}
.nav-item a {
  text-decoration: none;
}
.card-body .new {
font-size: 13px;
}
.round-img {
  text-align: center;
}

.rounded-circle {
  height: 80px;
  width: 80px;
}
.blog-head h3 {
  color: var(--color-title);
  font-weight: bolder;
}

.icon-menu {
  font-size: 23px;
}

.law {
  font-weight: 900;
  text-align: center;
}
.container-fluid {
  padding: 10px !important;
}

.main-head {
  font-size: 35px;
  color:  var(--color-title);
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
}
.logo a {
  text-decoration: none !important;
}
.nav-link {
  padding-right: 40px !important;
}
.navbar-nav a {
  text-decoration: none;
  color: var(--color-topic);
}
.navbar-nav a:hover {
  color:  var(--color-title);
}
.nav-link :hover {
  color:  var(--color-title);
  text-decoration: none;
}

.nav-item a {
  color: var(--color-topic);
  font-size: 15px;
}
.nav-item a:hover {
  color:  var(--color-title);
}

.menu{
    margin-right: 0px!important;
}
@media only screen and (max-width:600px){
  .EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__container {
   
    width: 100%!important;
   
}

}