@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100;1,300&family=Montserrat:wght@500&family=Playfair+Display&family=Poppins:wght@200&family=Raleway:ital,wght@1,900&family=Roboto+Serif:wght@100&family=Roboto:ital,wght@0,100;0,300;1,100&family=Shizuru&display=swap");


.footer {
  padding-bottom: 10px;
  
}
.temp {
  margin-top: 50px;
}
.temp1 {
  margin-top: 50px;

}
@media (max-width: 844px) {
  .temp1 {
    margin-top: 40px;
  }
  .temp {
    margin-top: 0px;
  }
}

.footer .copyright a {
  font-weight: 600;
  color: var(--color-title);
  text-decoration: none;
}


.lh-35 {
  line-height: 35px;
}

.logo {
  font-weight: 600;
  letter-spacing: 1px;
}

.logo span {
  color: var(--color-title);
}
.temp1 p {
  color: var(--color-footer);
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}

.divider {
  height: 3px;
  width: 40px;
  height: 5px;
  background:  var(--color-title);
}

.widget h4 {
  color:  var(--color-title);
}
.widget .footer-menu li {
  color: var(--color-footer);
  cursor: pointer;
}

.widget .footer-menu li:hover {
  color: var(--color-title);
}

.footer-contact-block span {
  font-weight: 400;
  color: var(--color-footer);
  /* text-decoration: none; */
}

.footer-contact-block i {
  font-size: 20px;
}
.footer-contact-block a {
  list-style-type: none;
  color: var(--color-footer);
  text-decoration: none;
}

.footer-btm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer-socials li .fb {
  width: 45px;
  height: 45px;
  background: var(--color-facebook);
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 14px;
}
.footer-socials li .tw {
  width: 45px;
  height: 45px;
  background: #57bafc;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 14px;
}
.footer-socials li .link {
  width: 45px;
  height: 45px;
  background: #0077b5;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 14px;
}

.widget-contact h6 {
  font-weight: 500;
  margin-bottom: 18px;
}
.read-or-hide{
  color:  var(--color-title);
  cursor: pointer;
  font-weight: 500;
}
